import logo from "./logo.svg";
import "./App.css";
import final1 from "./final1.png";
import final2 from "./final2.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <div className="imgContainer">
          <img src={final1} className="App-logo" alt="logo" />
          <img src={final2} className="App-logo" alt="logo" />
        </div>

        <p>

          This is Joshua S Moline. His past is littered with destroyed
          relationships and broken friendships. His drinking has caused him to
          do some terrible things to people who didn't deserve it. In particular
          one girl in 2014.....
        </p>
        <p>Check back on 10/15 for the full story</p>
        <div className="footer">
          <p>jsmoline12@gmail.com</p>
          <p>Feel free to reach out</p>
          <p>515-298-2493</p>
        </div> */}
        <p>Allow this site to serve as a obelisk of friendship.</p>
        <p>
          Even though we try to control the ocean, we cannot breathe under
          water.
        </p>
      </header>
    </div>
  );
}

export default App;
